import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { useMediaQuery } from '@material-ui/core'
import React, { PropsWithChildren, useMemo } from 'react'
import { theme } from '../../theme'
import { CreditUnionIcon, CreditUnionIconProps } from '../icon/CreditUnionIcon'
import { IconProps } from '../icon/icon'
import { DoubleTriangleSvg } from './doubletriangle'
import {
  ContentContainer,
  HeadingContainer,
  SectionLinkContainer,
  SectionTitleContainer,
  StyledCTASectionWrapper,
  StyledCTAWrapper,
  StyledChevronRightIcon,
  StyledClearFix,
  StyledCreditUnionIconContainer,
  StyledSvg,
  StyledTrapezoidBorder,
  Subtitle,
  Title,
} from './styledcontractcta'

export type ContactCTAWrapperProps = {
  //
}

export type ContactCTAHeadingProps = {
  //
}

export type ContactCTAHeadingWrapperProps = {
  //
}

export type ContactCTAContentWrapperProps = {
  //
}

export type ContactCTASectionProps = {
  href?: string
  iconStart?: IconProps['icon']
  children: Array<React.ReactNode>
  type?: 'wrapper' | 'content'
  target?: string
}

export type ContactCTATitleProps = {
  //
}

export type ContactCTAIconProps = {
  iconCode: CreditUnionIconProps['iconCode']
  variant?: boolean
  layout?: 'vertical' | 'horizontal'
}

export type ContactCTASubtitleProps = {
  //
}

export type ContactCTAProps = ({ headingWrapper?:boolean; contentWrapper?: boolean; heading?: boolean; section?: boolean; title?: boolean; icon?: boolean; subtitle?: boolean }) & (
// heading wrapper
| ({ headingWrapper:true } & ContactCTAHeadingWrapperProps )
// content wrapper
| ({ contentWrapper: true } & ContactCTAContentWrapperProps )
// heading content
| ({ heading: true } & ContactCTAHeadingProps )
// section 
| ({ section: true } & ContactCTASectionProps )
// title
| ({ title: true } & ContactCTATitleProps )
// subtitle
| ({ subtitle: true } & ContactCTASubtitleProps )
// icon
| ({ icon: true } & ContactCTAIconProps )
// Wrapper
| ContactCTAWrapperProps
)

export const ContactCTA: React.FC<PropsWithChildren<ContactCTAProps>> = ({ headingWrapper, contentWrapper, heading, section, title ,icon, subtitle, children, ...props }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'))
  const dimension = useMemo(() => {
    return isMobile || isTablet ? { w: 65, h: 102 } : { w: 142, h: 180 }
  }, [isMobile, isTablet])

  if(headingWrapper){
    return (
      <ContactCTAHeadingContainer {...props}>
        {children}
      </ContactCTAHeadingContainer>
    )
  }

  if(contentWrapper){
    return (
      <ContactCTAContentContainer {...props}>
        {children}
      </ContactCTAContentContainer>
    )
  }

  if(section){
    return (
      <ContactCTASectionContainer {...props}>
        {children}
        <React.Fragment></React.Fragment>
      </ContactCTASectionContainer>
    )
  }

  if(title){
    return (
      <ContactCTATitle {...props}>
        {children}
      </ContactCTATitle>
    )
  }

  if(subtitle){
    return (
      <ContactCTASubtitle {...props}>
        {children}
      </ContactCTASubtitle>
    )
  }

  if(icon) {
    return (
      <ContactCTAIcon
        {...props as ContactCTAIconProps}
        layout={ isMobile ? 'vertical' : 'horizontal' }>
        {children}
      </ContactCTAIcon>
    )
  }

  return (
    <ContactCTAContainer {...props}>
      <ContentContainer>
        <StyledTrapezoidBorder>{children}</StyledTrapezoidBorder>        
      </ContentContainer>
      <StyledSvg>
        <DoubleTriangleSvg
          width={dimension.w}
          height={dimension.h}
        />
      </StyledSvg>
    </ContactCTAContainer>
  )
}

export const ContactCTAContainer: React.FC<PropsWithChildren<ContactCTAWrapperProps>> = ({ children, ...props }) => (
  <StyledCTAWrapper {...props}>   
    {children}
  </StyledCTAWrapper>
)


export const ContactCTAHeadingContainer: React.FC<PropsWithChildren<ContactCTAHeadingWrapperProps>> = ({ children, ...props }) => (
  <HeadingContainer {...props}>
    {children}
  </HeadingContainer>
)


export const ContactCTAContentContainer: React.FC<PropsWithChildren<ContactCTAHeadingWrapperProps>> = ({ children, ...props }) => (
  <StyledCTASectionWrapper {...props}>
    {children}
  </StyledCTASectionWrapper>
)

export const ContactCTASectionContainer: React.FC<PropsWithChildren<ContactCTASectionProps>> = ({ children, type, href, iconStart, ...props }) => {
  if (!children) {
    return null
  }

  if (type === 'wrapper') {
    return (
      <SectionLinkContainer href={href} {...props}>
        {children}
        <StyledClearFix/>
        <StyledChevronRightIcon icon={faChevronRight}></StyledChevronRightIcon>
      </SectionLinkContainer>
    )
  }

  return (
    <SectionTitleContainer {...props}>{children}</SectionTitleContainer>
  )
}

const ContactCTATitle: React.FC<PropsWithChildren<ContactCTATitleProps>> = ({ children, ...props }) => (
  <Title {...props} variant="h4">
    {children}
  </Title>
)

const ContactCTASubtitle: React.FC<PropsWithChildren<ContactCTATitleProps>> = ({ children, ...props }) => (
  <Subtitle {...props}>
    {children}
  </Subtitle>
)

const ContactCTAIcon: React.FC<PropsWithChildren<ContactCTAIconProps>> = ({ iconCode, variant, layout, children, ...props }) => {
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <StyledCreditUnionIconContainer>
      <CreditUnionIcon variant={true} width={isTablet ? 25 : 38} height={isTablet ? 25 : 38} iconCode={iconCode} {...props}>
        {children}
      </CreditUnionIcon>
    </StyledCreditUnionIconContainer>
  )
}