import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'
import { Icon } from '../icon/icon'
import { irem, rem } from '../../utils'

export const StyledHeadingContent = styled.div`
  width: 100%;
  position: relative;
  padding: 0;
  background: transparent;
`

export const StyledSvg = styled.div`
  position: absolute;
  right: ${props => props.theme.typography.pxToRem(-10)};
  top: ${props => props.theme.typography.pxToRem(45)};

  ${props => props.theme.breakpoints.up('md')} {
    top: unset;
    bottom: 0;
    right: 0;
  }
`

export const StyledCTAWrapper = styled.div`
  min-width: calc(100% - ${props => props.theme.typography.pxToRem(20)});
  width: 100%;
  position: relative;
  padding-right: ${props => props.theme.typography.pxToRem(10)};
  margin: auto;
  margin-bottom: ${props => props.theme.typography.pxToRem(30)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding-right: ${props => props.theme.typography.pxToRem(6)};
    margin: auto;
  }

  ${props => props.theme.breakpoints.up('md')} {
    min-width: 100%;
    max-width: ${props => props.theme.typography.pxToRem(1245)};
    padding-right: 0;
  }
`

export const ContentContainer = styled.div`
  width: 100%;
  filter: drop-shadow(0 ${props => props.theme.typography.pxToRem(3)} ${props => props.theme.typography.pxToRem(15)} ${props => alpha(props.theme.palette.text.secondary, 0.19)});
  position: relative;
  background: transparent;
  padding: 0;
  z-index: 1;
  -webkit-transform: translate3d(0, 0, 0);
`

export const StyledTrapezoidBorder = styled.div`
  width: 100%;
  clip-path: polygon(88% 0, 100% ${props => props.theme.typography.pxToRem(136)}, 100% 100%, 0 100%, 0 0);
  background: ${props => props.theme.palette.common.white};
  padding: ${props => props.theme.typography.pxToRem(30)};
  z-index: 10;
  display: flex;
  flex-direction: column;

  ${props => props.theme.breakpoints.up('sm')} {
    clip-path: polygon(94% 0, 100% ${props => props.theme.typography.pxToRem(136)}, 100% 100%, 0 100%, 0 0);
  }

  ${props => props.theme.breakpoints.up('md')} {
    clip-path: polygon(0 0, 92% 0%, 100% 100%, 0% 100%);
    width: calc(100% - ${props => props.theme.typography.pxToRem(38)});
    padding-top: ${irem(40)};
    padding-bottom: ${irem(40)};
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    clip-path: polygon(0 0, 92% 0%, 100% 100%, 0% 100%);
  }
`

export const StyledChevronRightIcon = styled(Icon)`
  font-size: ${props => props.theme.typography.pxToRem(14)};
  transition: transform 0.2s ease;
  color: ${props => alpha(props.theme.palette.text.secondary, 0.7)};

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(17)};
  }
`

export const StyledClearFix = styled.div`
  flex-grow: 1;
`

export const SectionLinkContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${props => props.theme.typography.pxToRem(12)};
  width: 100%;
  text-decoration: none;
  color: ${props => props.theme.palette.text.secondary};
  padding: ${props => props.theme.typography.pxToRem(17)} 0;
  border-top: solid ${props => props.theme.typography.pxToRem(1)} ${props => alpha(props.theme.palette.text.secondary, 0.19)};

  ${props => props.theme.breakpoints.up('md')} {
    border-top: none;
    padding: ${props => props.theme.typography.pxToRem(22)} ${props => props.theme.typography.pxToRem(22)} ${props => props.theme.typography.pxToRem(22)}  ${props => props.theme.typography.pxToRem(40)};
  }

  &:hover {
    color: ${props => props.theme.palette.secondary.dark};

    > ${StyledChevronRightIcon} {
      transform: translateX(${props => props.theme.typography.pxToRem(-5)});
      color: ${props => props.theme.palette.secondary.dark};
    }
  }
`

export const StyledCTASectionWrapper = styled.div`
  width: 100%;

  ${props => props.theme.breakpoints.up('md')} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-right: ${props => props.theme.typography.pxToRem(55)};

    > ${SectionLinkContainer} {
      border-left: solid ${props => props.theme.typography.pxToRem(2)} ${props => alpha(props.theme.palette.text.secondary, 0.19)};
    }

    > ${SectionLinkContainer}:nth-child(1),
    > ${SectionLinkContainer}:nth-child(2) {
      border-bottom: solid ${props => props.theme.typography.pxToRem(2)} ${props => alpha(props.theme.palette.text.secondary, 0.19)};
    }
  }
`

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: ${props => props.theme.typography.pxToRem(15)} 0 ${props => props.theme.typography.pxToRem(25)} 0;

  ${props => props.theme.breakpoints.up('md')} {
    padding: 0 ${props => props.theme.typography.pxToRem(40)};
    min-width: ${props => props.theme.typography.pxToRem(240)};
    max-width: ${props => props.theme.typography.pxToRem(280)};
  }

  > h4 {
    font-weight: 500;
    color: ${props => props.theme.palette.text.primary};
    line-height: ${props => props.theme.typography.pxToRem(30)};
    font-size: ${props => props.theme.typography.pxToRem(25)};
  
    ${props => props.theme.breakpoints.up('md')} {
      line-height: ${props => props.theme.typography.pxToRem(36)};
      font-size: ${props => props.theme.typography.pxToRem(28)};
      margin-bottom: ${props => props.theme.typography.pxToRem(10)};
    }
  }

  > p {
    line-height: ${props => props.theme.typography.pxToRem(30)};
    font-size: ${props => props.theme.typography.pxToRem(15)};
    font-weight: normal;
    color: ${props => props.theme.palette.text.primary};

    ${props => props.theme.breakpoints.up('md')} {
      font-size: ${props => props.theme.typography.pxToRem(34)};
      font-size: ${props => props.theme.typography.pxToRem(22)};
      margin-bottom: 0;
      line-height: ${props => props.theme.typography.pxToRem(30)};
    }
  }
`

export const Title = styled(Typography)`
  color: ${props => props.theme.palette.text.primary};
  font-size: ${props => props.theme.typography.pxToRem(18)};
  font-weight: 500;
  margin-bottom: 0;

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(22)};
  }
`

export const Subtitle = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(14)};

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(17)};
  }
`

export const StyledCreditUnionIconContainer = styled.div`
  display: inherit;
  margin: auto 0;
  max-height: ${props => rem(32, props.theme)};
  max-width: ${props => rem(28, props.theme)};

  ${props => props.theme.breakpoints.up('md')} {
    max-height: ${props => rem(38, props.theme)};
    max-width: ${props => rem(38, props.theme)};
  }
`

export const SectionTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${props => props.theme.typography.pxToRem(2)};

  ${props => props.theme.breakpoints.up('md')} {
    padding-left: ${props => props.theme.typography.pxToRem(20)};
  }
`
