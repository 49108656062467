import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'
import { AccordionDetails, AccordionSummary, Accordion as BaseAccordion, Grid } from '@material-ui/core'
import React, {
  ChangeEvent,
  ComponentProps,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import { irem, rem } from '../../utils'
import { Icon } from '../icon/icon'

export type AccordionTitleProps = ComponentProps<typeof StyledAccordionHeading> & {
    //
  }
  
export type AccordionContentProps = ComponentProps<typeof AccordionContentContainer> & {
    //
  }
  
export type AccordionWrapperProps = ComponentProps<typeof Grid> & {
  multi?: boolean
  openAll?: boolean
}

export type AccordionPanelProps = ComponentProps<typeof StyledAccordionPanel> & {
  }

export type AccordionColumnProps = ComponentProps<typeof StyledColumn> & {
    //
  }

export type AccordionProps = 
    | (
      {
        column?: never
        panel?: never
        content?: never
        title?: never
      } & AccordionWrapperProps
    )
    | (
      {
        column?: never
        panel?: never
        content: true
        title?: never
      } & AccordionContentProps
    )
    | (
      {
        column?: never
        panel?: never
        content?: never
        title: true
      } & AccordionTitleProps
    )
    | (
      {
        column?: never
        panel: true
        content?: never
        title?: never
      } & AccordionPanelProps
    )
    | (
      {
        column: true
        panel?: never
        content?: never
        title?: never
      } & AccordionColumnProps
    )

type AccordionContextType = {
  openPanels: Array<string>
  pushPanel: (item: string) => void
  removePanel: (item: string) => void
  hasPanel: (item: string) => boolean
  clearPanels: () => void
  setPanels: (panels: string[]) => void
  multi: boolean
  openAll?: true
}

type AccordionChangeProps = (
  event: ChangeEvent<Record<string, unknown>>,
  expanded: boolean
) => void

export const StyledAccordionPanel = styled(BaseAccordion)`
  background-color: #f2f2f2;
  box-shadow: unset;
  margin-right: unset;
  width: 100%;
  margin-bottom: ${props => props.theme.typography.pxToRem(8)};

  ${props=> props.theme.breakpoints.up('sm')} {
    margin-right: ${props => props.theme.typography.pxToRem(20)};
    margin-bottom: ${irem(16)};
  }

  ${props=> props.theme.breakpoints.up('md')} {
    margin-bottom: ${irem(24)};
  }

  &.Mui-expanded {
    background-color: white;
    box-shadow: 0 ${props => props.theme.typography.pxToRem(3)} ${props => props.theme.typography.pxToRem(15)} #0000001a;
    border-radius: 0 0 ${props => props.theme.typography.pxToRem(4)} ${props => props.theme.typography.pxToRem(4)};
    border-top: ${props => props.theme.typography.pxToRem(3)} solid ${props => props.theme.palette.secondary.main};
    margin: 0 0 ${props => props.theme.typography.pxToRem(8)};

    ${props=> props.theme.breakpoints.up('sm')} {
      margin-right: ${props => props.theme.typography.pxToRem(20)};
      margin-bottom: ${irem(16)};
    }

    ${props=> props.theme.breakpoints.up('md')} {
      margin-bottom: ${irem(24)};
    }
  }

  &.MuiAccordion-root::before {
    opacity: 0;
  }

  .MuiAccordionSummary-root {
    ${props => props.theme.breakpoints.up('md')} {
      padding-left: ${props => props.theme.spacing(3)};
      padding-right: ${props => props.theme.spacing(3)};
    }
  }

  .MuiAccordionDetails-root {
    ${props => props.theme.breakpoints.up('md')} {
      padding: 0 ${props => props.theme.spacing(3)} ${props => props.theme.spacing(3)} ${props => props.theme.spacing(3)};
    }
  }
`


export const StyledAccordionHeading = styled(AccordionSummary)`
  border-radius: ${props => props.theme.typography.pxToRem(5)};
  font-size: ${irem(16)};
  line-height: ${irem(25)};
  font-weight: 500;
  color: ${props => props.theme.palette.text.secondary};
  opacity: 0.85;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  
  &.Mui-expanded {
    color: ${props => props.theme.palette.text.primary};
  }
  
  ${props=> props.theme.breakpoints.up('sm')} {
    font-size: ${irem(20)};
    line-height: ${irem(40)};
  }

  ${props=> props.theme.breakpoints.up('md')} {
    font-size: ${irem(22)};
    line-height: unset;
  }

  .MuiAccordionSummary-content {
    margin: ${props => rem(14, props.theme)} 0 ${props => rem(9, props.theme)};
    line-height: 1.5;

    ${props=> props.theme.breakpoints.up('sm')} {
      margin: ${irem(18)} 0 ${irem(17)};
    }

    ${props=> props.theme.breakpoints.up('md')} {
      margin: ${irem(22)} 0 ${irem(21)};
    }
  }
`

export const AccordionChevron = styled(Icon)`
  position: relative;
  top: 4px;
  font-size: ${props => props.theme.typography.pxToRem(20)};
  color: ${props => props.theme.palette.primary.main};

  ${props=> props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(25)};
  }
`

export const AccordionContentContainer = styled(AccordionDetails)`
  font-size: ${props => props.theme.typography.pxToRem(15)};
  flex-direction: column;

  ${props=> props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(17)};
    font-weight: normal;
    padding-top: 0;

    > p {
      margin-bottom: ${props => props.theme.typography.pxToRem(16)};
    }

    > hr {
      margin: ${props => props.theme.typography.pxToRem(16)};
    }
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  img {
    width: 100%;
  }
`

export const StyledColumn = styled(Grid)`
  margin-top: 0 !important;
  padding-right: ${props => props.theme.typography.pxToRem(20)};

  ${props=> props.theme.breakpoints.up('sm')} {
    margin-top: unset;
  }
`

const AccordionContext = createContext<AccordionContextType>({
  openPanels: [],
  multi: false,
  openAll: undefined,
  pushPanel: () => {},
  removePanel: () => {},
  hasPanel: () => false,
  setPanels: () => {},
  clearPanels: () => {},
})

export const Accordion: React.FC<PropsWithChildren<AccordionProps>> = ({ column, panel, title, content, ...props }) => {
  if (title) {
    return (
      <AccordionTitle { ...props } />
    )
  }
  
  if (content) {
    return (
      <AccordionContent { ...props } />
    )
  }

  if (panel) {
    return (
      <AccordionPanel {...props} />
    )
  }
    
  if (column) {
    return (
      <AccordionColumn { ...props } />
    )
  }
  
  return (
    <AccordionWrapper { ...props } />
  )
}
  
const AccordionTitle: React.FC<PropsWithChildren<AccordionTitleProps>> = ({ children, ...props }) => {
  return (
    <StyledAccordionHeading
      expandIcon={<AccordionChevron icon={faAngleDown} />} {...props}>
      { children }
    </StyledAccordionHeading>
  )
}
  
const AccordionContent: React.FC<PropsWithChildren<AccordionContentProps>> = ({ children, ...props }) => {
  return (<AccordionContentContainer {...props}>
    {children}
  </AccordionContentContainer>
  )
}

const AccordionPanel: React.FC<PropsWithChildren<AccordionPanelProps>> = ({ children, ...props }) => {
  const { hasPanel, pushPanel, removePanel, multi, setPanels, openAll } = useContext<AccordionContextType>(AccordionContext)
  const id = useMemo(()=>uuidv4(),[])

  const handleChange: AccordionChangeProps = useCallback((event, expanded) => {
    if (expanded) {
      if (multi) {
        pushPanel(id)
      } else {
        setPanels([id])
      }
    } else {
      removePanel(id)
    }
  }, [id, pushPanel, removePanel, setPanels, multi])

  return (
    <StyledAccordionPanel expanded={openAll || hasPanel(id)} onChange={handleChange} {...props}>
      { children }
    </StyledAccordionPanel>
  )
}

const AccordionWrapper: React.FC<PropsWithChildren<AccordionWrapperProps>> = ({ multi = false, openAll, children, ...props }) => {
  const [openPanels, setOpenPanels] = useState<AccordionContextType['openPanels']>([])

  const pushPanel = useCallback((item: string) => {
    setOpenPanels([ ...openPanels, item ])
  }, [setOpenPanels, openPanels])

  const removePanel = useCallback((item: string) => {
    setOpenPanels(openPanels.filter(panel => panel !== item))
  }, [setOpenPanels, openPanels])

  const hasPanel = useCallback((item: string) => openPanels.includes(item), [openPanels])

  const clearPanels = useCallback(() => {
    setOpenPanels([])
  }, [setOpenPanels])

  useEffect(() => {
    if (openAll) {
      clearPanels()
    }
  }, [clearPanels, openAll])

  const setPanels = setOpenPanels

  return (
    <AccordionContext.Provider value={{ openPanels, multi, pushPanel, removePanel, hasPanel, clearPanels, setPanels, openAll: openAll ? true : undefined }}>
      <Grid container direction="row">
        { children }
      </Grid>
    </AccordionContext.Provider>
  )
}

const AccordionColumn: React.FC<PropsWithChildren<AccordionColumnProps>> = ({ children, ...props }) =>
{
  return (
    <StyledColumn item xs={12} sm={12} md={6} {...props} >
      <Grid container direction="column">
        { children }
      </Grid>
    </StyledColumn>
  )
}
