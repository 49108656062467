import { gql } from '@apollo/client'
import React, { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'
import { SecondaryBanner, StyledContentContainer } from '../components/secondarybanner/SecondaryBanner'
import {
  StyledBreadcrumbs,
  StyledLinkSection,
  StyledSubtitle,
  StyledTitle,
} from '../components/secondarybanner/StyledSecondaryBanner'
import { rem } from '../utils'
import { isNotNull } from '../utils/nonNull'
import { SecondaryPageBannerFragment } from './__generated__/secondaryPageBanner'

export type SecondaryPageBannerProps = SecondaryPageBannerFragment & {
  breadcrumbs?: React.ReactNode
  after?: 'grey' | 'white' | 'light'
}

const diagonalSizeMobile = 32 as const
const diagonalSizeDesktop = 93 as const

const StyledSecondaryBanner = styled(SecondaryBanner)<{ breadcrumbs: React.ReactNode }>`
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - ${props => rem(diagonalSizeMobile, props.theme)}), 0 100%);
  padding-bottom: ${props => rem(30, props.theme)};

  > .MuiContainer-root {
    height: 100%;

    > .MuiGrid-container {
      height: 100%;

      > .MuiGrid-item {
        display: flex;
        flex-direction: column;
      }
    }
  }

  ${props => props.theme.breakpoints.up('md')} {
    padding-bottom: 0;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - ${props => rem(diagonalSizeDesktop, props.theme)}), 0 100%);

    .MuiBreadcrumbs-ol {
      margin-top: ${props => rem(20, props.theme)};
      max-width: ${props => rem(350, props.theme)};
      flex-wrap: nowrap;

      ${props => props.theme.breakpoints.up('md')} {
        max-width: ${props => rem(410, props.theme)};
      }

      .MuiBreadcrumbs-li {
        flex: 0 0 auto;
        white-space: nowrap;

        &:last-child {
          flex: 0 1 auto;
          overflow: hidden;

          > a {
            max-width: 100%;

            p {
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }

        &:focus-within {
          outline: ${props => props.theme.typography.pxToRem(1)} auto -webkit-focus-ring-color;
        }
      }
    }
  }

  ${props => !props.src && css`
    ${StyledTitle} {
      margin-top: ${props => rem(75, props.theme)};
      max-width: 100%;
      text-align: left;
      padding: 0;

      ${props => props.theme.breakpoints.up('md')} {
        margin-top: 0;
      }
    }

    ${StyledSubtitle} {
      max-width: ${props => rem(600, props.theme)};
      text-align: left;
    }

    ${StyledContentContainer} {
      ${props => props.theme.breakpoints.up('md')} {
        padding-bottom: ${props => rem(90, props.theme)};
      }
    }

    ${StyledBreadcrumbs} {
      background: none;
      margin: ${props => rem(10, props.theme)} 0 0 ${props => rem(3, props.theme)};

      ${props => props.theme.breakpoints.up('md')} {
        margin: 0;
      }
    }

    ${StyledLinkSection} {
      justify-content: flex-start;
    }
  `}
`

const StyledWrapper = styled.div<{ $after: SecondaryPageBannerProps['after'] }>`
  position: relative;

  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${props => props.$after === 'white' ? 'white' : (props => props.$after === 'grey' ? '#f2f2f2' : '#f8f8f8')};
    z-index: -1;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    height: ${props => rem(diagonalSizeMobile, props.theme)};

    ${props => props.theme.breakpoints.up('sm')} {
      height: ${props => rem(diagonalSizeDesktop, props.theme)};
    }
  }

  ${props => props.$after === 'white' && css`
    ${StyledSecondaryBanner} {
      ${props => props.theme.breakpoints.down('xs')} {
        padding-bottom: 0;
      }
    }
  `}
`

export const SecondaryPageBanner: React.FC<PropsWithChildren<SecondaryPageBannerProps>> = ({ after, breadcrumbs, title, subTitle, image, primaryButton, secondaryButton }) => {
  const imgSrc = image ? image.find(x => x?.__typename === 'images_Asset')?.url : null

  return (
    <StyledWrapper $after={after}>
      <StyledSecondaryBanner image breadcrumbs={breadcrumbs} src={imgSrc ?? undefined}>
        {title && (
          <SecondaryBanner title>{title}</SecondaryBanner>
        )}
        {subTitle && (
          <SecondaryBanner subtitle>{subTitle}</SecondaryBanner>
        )}
        {(primaryButton || secondaryButton) && (
          <SecondaryBanner section>
            {(primaryButton && primaryButton.__typename === 'linkField_Link' && isNotNull(primaryButton.url)) && (
              <SecondaryBanner cta buttonProps={{ primary: true, buttonColor: 'orange', target: primaryButton?.target === '_blank' ? '_blank' : '_self' }} href={primaryButton.url || '#'}>
                {primaryButton.customText}
              </SecondaryBanner>
            )}
            {(secondaryButton && secondaryButton.__typename === 'linkField_Link' && isNotNull(secondaryButton.url)) &&
              <SecondaryBanner cta buttonProps={{ primary: true, buttonColor: 'black', target: secondaryButton?.target === '_blank' ? '_blank' : '_self', size: 'small' }} href={secondaryButton.url || '#'}>
                {secondaryButton.customText}
              </SecondaryBanner>
            }
          </SecondaryBanner>
        )}
      </StyledSecondaryBanner>
    </StyledWrapper>
  )
}

export const SECONDARY_PAGE_BANNER_FRAGMENT = gql`
  fragment SecondaryPageBannerFragment on secondaryPageBanner_BlockType {
    title: secondaryBannerTitle
    subTitle: secondaryBannerSubtext
    image: secondaryBannerImage {
      url
    }
    primaryButton: secondaryBannerPrimaryButton {
      url
      customText
      target
    }
    secondaryButton: secondaryBannerSecondaryButton {
      url
      customText
      target
    }
  }
`
